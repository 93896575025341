import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '../authentication-services/session.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: SessionService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const token = this.authService.getToken();

    if (token) {
      console.log(state.url);
      if (state.url === '/') {
        // If user tries to access '/' with a token, redirect to dashboard
        this.router.navigate(['/account/dashboard']);
        return false;
      }
      return true; // Allow access to protected routes
    } else {
      if (state.url === '/account/dashboard') {
        // If user tries to access '/account/dashboard' without a token, redirect to '/'
        this.router.navigate(['/']);
        return false;
      }
      return true; // Allow access to public routes
    }
  }
}
