import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NOTIFICATION_COLOR, UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';
import { ApplicationService } from '../../services/application.service';

@Component({
  selector: 'app-other-application-permit-answer',
  templateUrl: './other-application-permit-answer.component.html',
  styleUrls: ['./other-application-permit-answer.component.scss']
})
export class OtherApplicationPermitAnswerComponent {
  @Input() inputData: any;
  @Output() backToParent = new EventEmitter();
  isReadyToSubmit!: boolean;
  submitted!: boolean;
  userForm!: UntypedFormGroup;
  // refurbrishmentForm!: UntypedFormGroup;
  reservedDocumentRequired: any[] = [];
  deleteData: any = {};
  uploadedDocuments: any[] = [];

  documentRequired: any[] = [];
  fileSubmit: any;
  fileData: any = {};

  typeOfUsers = [
    { id: '', name: '' }
  ]

  accountTypes: any[] = [
    // { id: '1', name: 'Land Owner' },
    { id: '2', name: 'Engineer / Firm' },
    { id: '3', name: 'Architect / Firm' }
  ];
  pageData: any = {};

  yesOrNo: any[] = [
    { id: 'Yes', name: 'Yes' },
    { id: 'No', name: 'No' },
    { id: 'Not applicable', name: 'Not applicable' }
  ];

  equipmentCapacities = [
    { id: 'Rain water harvesting', name: 'Rain water harvesting' },
    { id: 'Sewage treatment plant', name: 'Sewage treatment plant' },
    { id: 'Storm water drainages along adjacent plot', name: 'Storm water drainages along adjacent plot' },
  ];

  constructionMethods: any = [
    { id: 'Wall', name: 'Wall' },
    { id: 'Roof', name: 'Roof' },
    { id: 'Foundation', name: 'Foundation' },
    { id: 'Structural elements', name: 'Structural elements' },
  ]






  constructor(
    private modalService: NgbModal,
    private applicationService: ApplicationService,
    private utilService: UtilService,
    private formBuilder: UntypedFormBuilder
  ) { }





  ngOnInit() {
    console.log(this.inputData);
    this.pageData.permitType = this.inputData.permitTypes.find((x: any) => x.id === this.inputData.userForm.permitTypeId);
    console.log(this.pageData);
    // *Refurbrishment
    this.userForm = this.formBuilder.group({
      doYouHaveTheOccupancy: [this.inputData.otherApplicationValue?.isOtherReady ? this.inputData.otherApplicationValue.other.doYouHaveTheOccupancy : ''],
      isFastAidBox: [this.inputData.otherApplicationValue?.isOtherReady ? this.inputData.otherApplicationValue.other.isFastAidBox : ''],
      disabilityToiletsFlipUpGrabBars: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.disabilityToiletsFlipUpGrabBars : ''],
      paraLighteningSystem: [this.inputData.otherApplicationValue?.isOtherReady ? this.inputData.otherApplicationValue.other.paraLighteningSystem : ''],
      equipmentCapacity: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.equipmentCapacity : ''],
      constructionMethod: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.constructionMethod : ''],
      fireAlarmSystemWithAnAlarmBellOnEach: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.fireAlarmSystemWithAnAlarmBellOnEach : ''],
      whyNotFireAlarmSystemWithAnAlarmBellOnEach: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.whyNotFireAlarmSystemWithAnAlarmBellOnEach : ''],
      fireExtinguishersEvery50mOnEachFloor: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.fireExtinguishersEvery50mOnEachFloor : ''],
      whyNotFireExtinguishersEvery50mOnEachFloor: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.whyNotFireExtinguishersEvery50mOnEachFloor : ''],
      functioningExitSignsOnEachFloor: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.functioningExitSignsOnEachFloor : ''],
      whyNotfunctioningExitSignsOnEachFloor: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.whyNotfunctioningExitSignsOnEachFloor : ''],
      anEmergencyExitOnEachFloor: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.anEmergencyExitOnEachFloor : ''],
      whyNotanEmergencyExitOnEachFloor: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.whyNotanEmergencyExitOnEachFloor : ''],
      floorPlanOnEachLevel: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.floorPlanOnEachLevel : ''],
      whyNotfloorPlanOnEachLevel: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.whyNotfloorPlanOnEachLevel : ''],
      numberSignOnEachFloor: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.numberSignOnEachFloor : ''],
      whyNotnumberSignOnEachFloor: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.whyNotnumberSignOnEachFloor : ''],
      signForbiddingTheUseOfElevatorsInCaseOfFire: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.signForbiddingTheUseOfElevatorsInCaseOfFire : ''],
      whyNotsignForbiddingTheUseOfElevatorsInCaseOfFire: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.whyNotsignForbiddingTheUseOfElevatorsInCaseOfFire : ''],
      landingSpaceOnTopOfTheBuildingForHelicopters: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.landingSpaceOnTopOfTheBuildingForHelicopters : ''],
      whyNotlandingSpaceOnTopOfTheBuildingForHelicopters: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.whyNotlandingSpaceOnTopOfTheBuildingForHelicopters : ''],
      CCTVCameras: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.CCTVCameras : ''],
      whyNotCCTVCameras: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.whyNotCCTVCameras : ''],
      WalkThroughAndHeldMetalDetect: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.WalkThroughAndHeldMetalDetect : ''],
      whyNotWalkThroughAndHeldMetalDetect: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.whyNotWalkThroughAndHeldMetalDetect : ''],
      UnderSearchMirror: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.UnderSearchMirror : ''],
      whyNotUnderSearchMirror: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.whyNotUnderSearchMirror : ''],
      LuggageScanners: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.LuggageScanners : ''],
      whyNotLuggageScanners: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.whyNotLuggageScanners : ''],
      PlatesIndicatingEmergencyResponseUnitsPhoneNumbers: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.PlatesIndicatingEmergencyResponseUnitsPhoneNumbers : ''],
      whyNotPlatesIndicatingEmergencyResponseUnitsPhoneNumbers: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.whyNotPlatesIndicatingEmergencyResponseUnitsPhoneNumbers : ''],
      EmergencyEvacuationPlan: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.EmergencyEvacuationPlan : ''],
      whyNotEmergencyEvacuationPlan: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.whyNotEmergencyEvacuationPlan : ''],
      SecurityManagerAndStaffCameras: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.SecurityManagerAndStaffCameras : ''],
      whyNotSecurityManagerAndStaffCameras: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.whyNotSecurityManagerAndStaffCameras : ''],
      AnInternalCommunicationSystem: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.AnInternalCommunicationSystem : ''],
      whyNotAnInternalCommunicationSystem: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.whyNotAnInternalCommunicationSystem : ''],
      BroadBandInternetServices: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.BroadBandInternetServices : ''],
      whyNotBroadBandInternetServices: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.whyNotBroadBandInternetServices : ''],
      StaffAndVisitorAccessCards: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.StaffAndVisitorAccessCards : ''],
      whyNotStaffAndVisitorAccessCards: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.whyNotStaffAndVisitorAccessCards : ''],
      applicationForFixedTelephoneLineConnection: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.applicationForFixedTelephoneLineConnection : ''],
      areThereAnyFacilitiesForTheDisabledProvidedBuilding: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.areThereAnyFacilitiesForTheDisabledProvidedBuilding : ''],
      whyNotAreThereAnyFacilitiesForTheDisabledProvidedBuilding: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.whyNotAreThereAnyFacilitiesForTheDisabledProvidedBuilding : ''],

      stageOfConstruction: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.stageOfConstruction : ''],
      supervisingFirmSiteEngineer: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.supervisingFirmSiteEngineer : ''],
      remarks: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.userTypeId : ''],
      userTypeId: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.licenseNumber : ''],
      licenseNumber: [this.inputData.otherApplicationValue.isOtherReady ? this.inputData.otherApplicationValue.other.licenseNumber : ''],
      dateForRequestedInspection: [this.getCurrentDate()]


    })
    // this.userForm = this.formBuilder.group({

    // })
  }

  getCurrentDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2); // Add leading zero to month
    const day = ('0' + today.getDate()).slice(-2); // Add leading zero to day
    return `${year}-${month}-${day}`;
  }


  enableUserType() { }



  focusOutFunction() {
    this.backToParent.emit(this.userForm.value);
  }


  focusOutAndFindUser() {
    if (this.userForm.value.userTypeId === '2') {
      let replacedString = this.userForm.value.licenseNumber.replace(/\//g, "%2F");
      if (replacedString) {
        this.applicationService.findAllWithPath(environment.integrationUrl + 'integration/engineer/{registrationNumber}?registrationNumber=' + replacedString)
        .subscribe(
          data => {
            if (data) {
              this.userForm.controls['supervisingFirmSiteEngineer'].setValue(data.name);
            }
          },
        )
      }
    } else if (this.userForm.value.userTypeId === '3') {


      this.applicationService.findAllWithPath(environment.integrationUrl + 'integration/engineer')
        .subscribe(
          data => {
            console.log(this.userForm.value.licenseNumber);

            console.log(data.find((x: any) => x.value.registrationindex === this.userForm.value.licenseNumber));
            let findArchtc = data.find((x: any) => x.value.registrationindex === this.userForm.value.licenseNumber);
            this.userForm.controls['supervisingFirmSiteEngineer'].setValue(findArchtc.value.last_name + ' ' + findArchtc.value.name);
          }
        )


      // this.authService.findAllWithPath(environment.integrationUrl + 'integration/engineer')
      // .subscribe(
      //   data => {
      //     let findArchtc = this.engineers.find((x: any) => x.value.registrationindex === this.accountTypeForm.value.licenseNumber).value;
      //     if (findArchtc) {
      //       this.isVerified();
      //       this.accountTypeForm.controls['archictData'].setValue(findArchtc);
      //       this.registerDetails();
      //     } else {
      //       this.registerDetails();
      //     }
      //   },
      // )
    }
  }


  close() {

  }



  loadUploadedDocuments() {
    if (this.inputData.applicationSaved) {
      this.applicationService.findAllWithPath(environment.documentUrl + 'DocMgt/documents/' + this.inputData.applicationSaved.id)
        .subscribe(
          documents => {
            this.uploadedDocuments = documents;
            if (this.uploadedDocuments.length === this.reservedDocumentRequired.length) {
              this.isReadyToSubmit = true;
            }
            this.documentRequired = this.reservedDocumentRequired;
            this.documentRequired.forEach((document: any) => {
              let findElement = this.uploadedDocuments.find((x: any) => x.requiredDocumentId === document.id);
              if (findElement) {
                document.uploaded = findElement
              }
            });
          }
        )
    }

  }






  onSubmit(form: any) {

  }



  getFileDetails(e: any, docInfo: any) {
    // this.fileData = this.utilService.getFileDetails(e);
    const fileInput: any = e.target as HTMLInputElement;

    if (docInfo.code === '1') {
      if (this.utilService.getCheckFileExtension(e).toLowerCase() === 'pdf') {
        this.utilService.showNotification(NOTIFICATION_COLOR.error, 'Please upload BOQ (Bill of Quantities file)', "bottom", "center");

      } else {
        this.fileData.fileName = e.target?.files[0]?.name;
        this.fileData.fileNameDisplay = e.target?.files[0]?.name;
        this.userForm.controls['documentRequiredId'].setValue(docInfo.id);
        this.handleFileSelected(e);
        this.fileData.myFiles = [];
        for (var i = 0; i < e.target.files.length; i++) {
          this.fileData.myFiles.push(e.target.files[i]);
        }
        this.fileData.file = e.target.files;
        this.onSubmitFile();
      }
    } else {
      this.fileData.fileName = e.target?.files[0]?.name;
      this.fileData.fileNameDisplay = e.target?.files[0]?.name;
      this.userForm.controls['documentRequiredId'].setValue(docInfo.id);
      this.handleFileSelected(e);
      if (this.fileData.fileType !== 'excel' && this.utilService.getCheckFileExtension(e).toLowerCase() === 'pdf') {
        this.fileData.myFiles = [];
        for (var i = 0; i < e.target.files.length; i++) {
          this.fileData.myFiles.push(e.target.files[i]);
        }
        if (this.fileData.size > this.utilService.fileSize) {
          this.utilService.showNotification(
            "snackbar-danger",
            "Error: " + 'File will not be saved because it is too big, try 20MB of size',
            "top",
            "end"
          );
          fileInput.value = ''; // Reset input
          return; // Exit if validation fails
        } else {
          this.fileData.file = e.target.files;
          this.onSubmitFile();
        }
      } else {
        this.utilService.showNotification(
          "snackbar-danger",
          "Error: " + 'PDF File allowed only',
          "top",
          "end"
        );
        // Reset input after error
        setTimeout(() => {
          fileInput.value = '';
        }, 0);
      }
    }
    // this.userForm.controls['documentRequiredId'].setValue(docInfo.id);
    // this.onSubmitFile();
  }


  async handleFileSelected(event: any) {
    const size = event.srcElement.files[0].size;
    if (size < 1000 * 1000 * 1000) {
      this.fileData.size = size / 1000 / 1000;
      this.fileData.unit = "mb";
    }
  }


  onSubmitFile() {
    // if (fileDat) {
    let formData = new FormData();
    const fileList: FileList = this.fileData.file;
    if (fileList && fileList[0]) {
      const file: File = fileList[0];
      this.fileData.fileNameDisplay = file.name
      formData.append('file', file, file.name);
    }
    formData.append('requiredDocumentId', this.userForm.value.documentRequiredId);
    formData.append('applicationId', this.inputData.applicationSaved.id);
    // formData.append('applicationId', this.userForm.value.applicationId);
    this.fileSubmit = true;
    this.applicationService.saveAssetWithPathFormData(formData, environment.documentUrl + 'DocMgt/upload')
      .subscribe(
        data => {
          // this.utilService.showNotification(NOTIFICATION_COLOR.success, "Your application saved successfully", "bottom", "center");
          this.loadUploadedDocuments();
          this.fileSubmit = false;
        }, error => {
          this.fileSubmit = false;
        }
      )
    // } else {
    //   this.submitted = false;
    //   this.utilService.showNotification(NOTIFICATION_COLOR.error, "File is not provided", "bottom", "center");
    // }

  }



}
