import { Component, inject } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
// import { Crisp } from "crisp-sdk-web";
import { AppConfig } from './app.config';
import { NOTIFICATION_COLOR, UtilService } from './shared/services/util.service';
import { IdleService } from './shared/services/idle.service';
import { Router } from '@angular/router';
import { SessionService } from './authentication-services/session.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    idleService = inject(IdleService);
    private subscription!: Subscription;
    private idleSubscription?: Subscription;
    upiFinder = '';
    showChat: boolean = false;
    toggleIcon: string = 'open'; // Initially set to open icon
    toggleChat() {
        this.showChat = !this.showChat;
        this.toggleIcon = this.showChat ? 'close' : 'open'; // Change the icon
        console.log(this.showChat);
    }

    constructor(
        private translateService: TranslateService,
        private utilService: UtilService,
        private appConfig: AppConfig,
        private sessionService: SessionService,
        private router: Router) {
        // const userLang = navigator.language || 'en';
        // const languageCode = userLang.split('-')[0];
        // this.translateService.setDefaultLang(languageCode);
        // this.translateService.use(languageCode);

        translateService.addLangs(['en', 'kin', 'fr']);
        translateService.setDefaultLang('en');

        // this.utilService.showNotification(NOTIFICATION_COLOR.error, "Invalid username or password", "bottom", "center");


        // Crisp.configure("b8fb124c-627f-474c-a457-6624926dc4e1");

        // this.utilService.encryptString("packmutangana@gmail.com", '123456').then((encryptedString) => {
        //     console.log(encryptedString); // Logs the resolved value
        // })

        // const encryptedString = this.utilService.encryptString("packmutangana@gmail.com", '123456');
        // console.log(encryptedString.__zone_symbol__value)
    }


    ngOnInit(): void {
        this.idleSubscription = this.idleService.idleState.subscribe((isIdle) => {
            if (isIdle) {
                // console.log('User is idle');
                this.logout();
            } else {
                // console.log('User is active');
            }
        });


        this.subscription = interval(1000).subscribe(() => {
            // this.performCheck();
            console.log(this.router.url);
            if (!this.sessionService.getToken() &&
                this.router.url !== '/login'
                && this.router.url !== '/register'
                && this.router.url !== '/2-factor-auth'
                && this.router.url !== '/forgot-password'
                && this.router.url !== '/email/verify'
                && !this.router.url.includes('reset-password')
                && !this.router.url.includes('2-factor-auth')
                && !this.router.url.includes('/verification/certificate')
            ) {
                this.router.navigate(['/auth']);
            }
        });
    }

    logout() {
        localStorage.removeItem(this.appConfig.JWT_Token);
        localStorage.removeItem(this.appConfig.Session_User);
        localStorage.removeItem(this.appConfig.UPI_NEW_INFO);
        localStorage.removeItem(this.appConfig.UPI_old_INFO);
        localStorage.removeItem(this.appConfig.PERMI_ACCESS);
        this.router.navigate(['']);
        // this.router.navigate(['/auth/login']);
    }

    ngOnDestroy(): void {
        if (this.idleSubscription) {
            this.idleSubscription.unsubscribe();
        }
        // throw new Error('Method not implemented.');


        if (this.subscription) {
            this.subscription.unsubscribe();
        }

    }


    onUserAction() {
        this.idleService.resetTimer();
    }

}
