<div class="kbkchat">
  <div class="kbkchat-comb">
    <div class="kbkchat-convo">
      <!-- <label class="stchbox">
        <input type="checkbox" />
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <title>ikon-swtch</title>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <g id="ikon-swtch">
                <rect class="cls-1" width="24" height="24" />
                <path class="cls-2"
                  d="M8,22a1,1,0,0,1-.71-.29l-4-4a1,1,0,0,1-.21-.33A1,1,0,0,1,3,17H3a1,1,0,0,1,.08-.38,1,1,0,0,1,.21-.33l4-4a1,1,0,0,1,1.42,1.42L6.41,16H16a1,1,0,0,1,0,2H6.41l2.3,2.29a1,1,0,0,1,0,1.42A1,1,0,0,1,8,22Zm8-10a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L17.59,8H8A1,1,0,0,1,8,6h9.59l-2.3-2.29a1,1,0,0,1,1.42-1.42l4,4a1,1,0,0,1,.21.33A1,1,0,0,1,21,7h0a1,1,0,0,1-.08.38,1,1,0,0,1-.21.33l-4,4A1,1,0,0,1,16,12Z" />
              </g>
            </g>
          </g>
        </svg>
      </label> -->
      <div class="kbkchat-header">
        <div class="kbkchat-header-content">
          <div class="kbkchat-userss">
            <!-- <div class="kbkchat-profile">
              <img src="assets/imgs/profile.jpg" alt="" />
            </div> -->
            <div class="kbkchat-profile">
              <img src="assets/imgs/profile1.svg" alt="" />
            </div>
          </div>
          <div class="kbkchat-info">
            <!-- <p class="kbkchat-title">Any Questions? Chat with Kubaka!</p>
            <p class="kbkchat-status">Get reply instantly</p> -->
            <p class="kbkchat-title">{{ "chatBotLabels.title" | translate }}</p>
            <p class="kbkchat-status">{{ "chatBotLabels.subTitle" | translate }}</p>
          </div>
        </div>
      </div>

      <div class="kbkchat-body">
        <div>
          <!-- header required -->
          <div class="kbkchat-message-static">
            <div class="kbkchat-b">
              <div class="kbkchat-message-box">
                <span class="kbkchat-bubble">{{ "chatBotLabels.Welcome" | translate }}! <strong>{{currentUser ? currentUser.data.user.firstName + ' ' + currentUser.data.user.lastName:  ''}}</strong> </span>
                <!-- <span class="kbkchat-bubble">Hello</span> -->
              </div>
            </div>
            <div class="kbkchat-u">
              <form class="kbkchat-message-form" [formGroup]="userForm" (ngSubmit)="onSubmit()">
                <div class="kbkchat-input">
                  <input id="phoneNumber" type="number" formControlName="phoneNumber" class="form-control compact-input"
                  noNegative
                    (keydown.enter)="findThePhoneConversation()" placeholder="Type your phone here and hit enter" />
                </div>
                <div class="kbkchat-input">
                  <select id="issueCategoryId" formControlName="issueCategoryId">
                    <option value="" disabled>{{ "chatBotLabels.Selectcategory" | translate }}</option>
                    <option *ngFor="let it of issueCategories" [value]="it.id"> {{ it.name }} </option>
                  </select>
                </div>
                <div class="kbkchat-input">
                  <select id="agencyId" formControlName="agencyId">
                    <option value="" disabled>{{ "chatBotLabels.Selectagency" | translate }}</option>
                    <option *ngFor="let it of agencies" [value]="it.id"> {{ it.name }} </option>
                  </select>
                </div>
              </form>
            </div>
          </div>
          <!-- header required -->
          <div *ngFor="let message of messages" class="kbkchat-message">
            <div class="kbkchat-b" *ngIf="message.isReply === '1'">
              <div class="kbkchat-message-box">
                <span class="kbkchat-bubble">{{ message.content }}</span>
              </div>
            </div>
            <div class="kbkchat-u" *ngIf="message.isReply === '0'">
              <div class="kbkchat-message-box">
                <span class="kbkchat-bubble">{{ message.content }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="kbkchat-footer">
        <input [(ngModel)]="userMessage" type="text" placeholder="Compose your message..." />
        <button class="send-btn" (click)="sendMessage()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>ikon-snd</title>
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <g id="ikon-snd">
                  <rect class="cls-1" width="24" height="24" />
                  <path class="cls-9"
                    d="M13.55,22.5a1,1,0,0,1-.91-.59l-3.25-7.3-7.3-3.25a1,1,0,0,1,.08-1.85l17-5.95a.69.69,0,0,1,.24-.05h.19a1,1,0,0,1,.62.29h0a1,1,0,0,1,.29.63,1.09,1.09,0,0,1,0,.18.69.69,0,0,1,0,.24l-6,17a1,1,0,0,1-.9.67Zm-2.21-8.43,2.1,4.72L17.11,8.31ZM5.21,10.56l4.72,2.1,5.76-5.77Z" />
                </g>
              </g>
            </g>
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>
<!-- <div class="chat-container">
    <div class="chat-header">
        <div class="chat-header-content">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40"
                class="chat-profile-icon">
                <circle cx="12" cy="12" r="12" fill="#007BFF" /> 
                <path d="M12 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0 2c-3.32 0-6 2.69-6 6v1h12v-1c0-3.31-2.68-6-6-6z"
                    fill="white" />
            </svg>
            <div class="chat-info">
                <p class="chat-title">Questions? Chat with us!</p>
                <p class="chat-status">Was last active 10/21/2024</p>
            </div>
        </div>
    </div>
    <div class="chat-body">
        <div *ngFor="let message of messages" class="chat-message">
            <span class="chat-bubble">{{ message }}</span>
        </div>
    </div>
    <div class="chat-footer">
        <input [(ngModel)]="userMessage" type="text" class="chat-input" placeholder="Compose your message...">
        <button class="send-btn" (click)="sendMessage()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
                <polygon fill="#007BFF" points="2,21 23,12 2,3 2,10 17,12 2,14" /> 
            </svg>
        </button>
    </div>
</div> -->
<div class="kbkchat" style="display: none;">
  <div class="kbkchat-comb">
    <div class="kbkchat-convo">
      <label class="stchbox">
        <input type="checkbox" />
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <title>ikon-swtch</title>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <g id="ikon-swtch">
                <rect class="cls-1" width="24" height="24" />
                <path class="cls-2"
                  d="M8,22a1,1,0,0,1-.71-.29l-4-4a1,1,0,0,1-.21-.33A1,1,0,0,1,3,17H3a1,1,0,0,1,.08-.38,1,1,0,0,1,.21-.33l4-4a1,1,0,0,1,1.42,1.42L6.41,16H16a1,1,0,0,1,0,2H6.41l2.3,2.29a1,1,0,0,1,0,1.42A1,1,0,0,1,8,22Zm8-10a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L17.59,8H8A1,1,0,0,1,8,6h9.59l-2.3-2.29a1,1,0,0,1,1.42-1.42l4,4a1,1,0,0,1,.21.33A1,1,0,0,1,21,7h0a1,1,0,0,1-.08.38,1,1,0,0,1-.21.33l-4,4A1,1,0,0,1,16,12Z" />
              </g>
            </g>
          </g>
        </svg>
      </label>
      <div class="kbkchat-header">
        <div class="kbkchat-header-content">
          <!-- <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="40"
        height="40"
        class="kbkchat-profile-icon"
      >
        <circle cx="12" cy="12" r="12" fill="#007BFF" />
        <path
          d="M12 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0 2c-3.32 0-6 2.69-6 6v1h12v-1c0-3.31-2.68-6-6-6z"
          fill="white"
        />
      </svg> -->
          <div class="kbkchat-userss">
            <div class="kbkchat-profile">
              <img src="assets/imgs/profile.jpg" alt="" />
            </div>
            <div class="kbkchat-profile">
              <img src="assets/imgs/profile1.svg" alt="" />
            </div>
          </div>
          <div class="kbkchat-info">
            <p class="kbkchat-title">Any Questions? Chat with Kubaka!</p>
            <p class="kbkchat-status">Get reply instantly</p>
          </div>
          <!-- <button class="kbk-btn kbk-btn-sm kbk-btn-close"></button> -->
        </div>
      </div>
      <div class="kbkchat-body" style="display: none">
        <!-- Display the messages at the top -->
        <div *ngFor="let message of messages" class="kbkchat-message">
          <span class="kbkchat-bubble">{{ message }}</span>
        </div>
        <!-- Form Section with names and category fields -->
        <form [formGroup]="userForm" (ngSubmit)="onSubmit()" class="form-inside-chat">
          <div class="form-group compact-form-group">
            <input id="senderNames" type="text" formControlName="senderNames" class="form-control compact-input"
              placeholder="Enter your name" />
          </div>
        </form>
      </div>
      <div class="kbkchat-body">
        <!-- <form [formGroup]="userForm"  (ngSubmit)="findThePhoneConversation()" class="form-inside-chat" *ngIf="!isOpenConversation">
      <div class="form-group compact-form-group">
        <input id="phoneNumber" type="text" formControlName="phoneNumber" 
        class="form-control compact-input"
        (keydown.enter)="findThePhoneConversation()"
          placeholder="Type your phone here and hit enter" />
      </div>
    </form> -->
        <!-- *ngIf="isOpenConversation" -->
        <div>
          <div *ngFor="let message of messages" class="kbkchat-message">
            <div class="kbkchat-b">
              <div class="kbkchat-message-box">
                <span class="kbkchat-bubble">Welcome! <strong>Honorine</strong> </span>
                <span class="kbkchat-bubble">{{ message }}</span>
              </div>
              <form style="display: none" class="kbkchat-message-form" [formGroup]="userForm" (ngSubmit)="onSubmit()">
                <div class="form-group compact-form-group">
                  <input id="phoneNumber" type="text" formControlName="phoneNumber" class="form-control compact-input"
                    (keydown.enter)="findThePhoneConversation()" placeholder="Type your phone here and hit enter" />
                </div>
                <div class="kbkchat-input">
                  <select id="issueCategoryId" formControlName="issueCategoryId">
                    <option value="" disabled>Select category</option>
                    <option *ngFor="let it of issueCategories" [value]="it.id"> {{ it.name }} </option>
                  </select>
                </div>
                <div class="kbkchat-input">
                  <select id="agencyId" formControlName="agencyId">
                    <option value="" disabled>Select agency</option>
                    <option *ngFor="let it of agencies" [value]="it.id"> {{ it.name }} </option>
                  </select>
                </div>
              </form>
            </div>
            <div class="kbkchat-u">
              <div class="kbkchat-message-box">
                <span class="kbkchat-bubble">{{ message }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Footer for sending a message -->
      <div class="kbkchat-footer">
        <input [(ngModel)]="userMessage" type="text" placeholder="Compose your message..." />
        <button class="send-btn" (click)="sendMessage()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>ikon-snd</title>
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <g id="ikon-snd">
                  <rect class="cls-1" width="24" height="24" />
                  <path class="cls-9"
                    d="M13.55,22.5a1,1,0,0,1-.91-.59l-3.25-7.3-7.3-3.25a1,1,0,0,1,.08-1.85l17-5.95a.69.69,0,0,1,.24-.05h.19a1,1,0,0,1,.62.29h0a1,1,0,0,1,.29.63,1.09,1.09,0,0,1,0,.18.69.69,0,0,1,0,.24l-6,17a1,1,0,0,1-.9.67Zm-2.21-8.43,2.1,4.72L17.11,8.31ZM5.21,10.56l4.72,2.1,5.76-5.77Z" />
                </g>
              </g>
            </g>
          </svg>
        </button>
      </div>
    </div>
    <div class="kbkchat-int">
      <label class="stchbox">
        <input type="checkbox" />
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <title>ikon-swtch</title>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <g id="ikon-swtch">
                <rect class="cls-1" width="24" height="24" />
                <path class="cls-2"
                  d="M8,22a1,1,0,0,1-.71-.29l-4-4a1,1,0,0,1-.21-.33A1,1,0,0,1,3,17H3a1,1,0,0,1,.08-.38,1,1,0,0,1,.21-.33l4-4a1,1,0,0,1,1.42,1.42L6.41,16H16a1,1,0,0,1,0,2H6.41l2.3,2.29a1,1,0,0,1,0,1.42A1,1,0,0,1,8,22Zm8-10a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L17.59,8H8A1,1,0,0,1,8,6h9.59l-2.3-2.29a1,1,0,0,1,1.42-1.42l4,4a1,1,0,0,1,.21.33A1,1,0,0,1,21,7h0a1,1,0,0,1-.08.38,1,1,0,0,1-.21.33l-4,4A1,1,0,0,1,16,12Z" />
              </g>
            </g>
          </g>
        </svg>
      </label>
      <div class="kbkchat-header">
        <div class="kbkchat-header-content">
          <!-- <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="40"
        height="40"
        class="kbkchat-profile-icon"
      >
        <circle cx="12" cy="12" r="12" fill="#007BFF" />
        <path
          d="M12 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0 2c-3.32 0-6 2.69-6 6v1h12v-1c0-3.31-2.68-6-6-6z"
          fill="white"
        />
      </svg> -->
          <div class="kbkchat-userss">
            <div class="kbkchat-profile">
              <img src="assets/imgs/profile.jpg" alt="" />
            </div>
          </div>
          <div class="kbkchat-info">
            <h3>Welcome at KUBAKA</h3>
          </div>
          <div class="kbkchat-info">
            <!-- <p class="kbkchat-title">Any Questions? Chat with Kubaka!</p> -->
            <p class="kbkchat-status">Get reply instantly</p>
          </div>
          <!-- <button class="kbk-btn kbk-btn-sm kbk-btn-close"></button> -->
        </div>
      </div>
      <div class="kbkchat-body">
        <!-- <form [formGroup]="userForm"  (ngSubmit)="findThePhoneConversation()" class="form-inside-chat" *ngIf="!isOpenConversation">
      <div class="form-group compact-form-group">
        <input id="phoneNumber" type="text" formControlName="phoneNumber" 
        class="form-control compact-input"
        (keydown.enter)="findThePhoneConversation()"
          placeholder="Type your phone here and hit enter" />
      </div>
    </form> -->
        <!-- *ngIf="isOpenConversation" -->
        <div>
          <div *ngFor="let message of messages" class="kbkchat-message">
            <div class="kbkchat-b">
              <div class="kbkchat-message-box">
                <span class="kbkchat-bubble">Please fill out all required fields and click Submit button at the bottom
                  to start chat with KUBAKA</span>
              </div>
            </div>
            <div class="kbkchat-u">
              <!-- <div class="kbkchat-message-box">
                <span class="kbkchat-bubble">{{ message }}</span>
              </div> -->
              <form class="kbkchat-message-form" [formGroup]="userForm" (ngSubmit)="onSubmit()">
                <div class="kbkchat-input">
                  <input id="phoneNumber" type="text" formControlName="phoneNumber" class="form-control compact-input"
                    (keydown.enter)="findThePhoneConversation()" placeholder="Type your phone here and hit enter" />
                </div>
                <div class="kbkchat-input">
                  <select id="issueCategoryId" formControlName="issueCategoryId">
                    <option value="" disabled>Select category</option>
                    <option *ngFor="let it of issueCategories" [value]="it.id"> {{ it.name }} </option>
                  </select>
                </div>
                <div class="kbkchat-input">
                  <select id="agencyId" formControlName="agencyId">
                    <option value="" disabled>Select agency</option>
                    <option *ngFor="let it of agencies" [value]="it.id"> {{ it.name }} </option>
                  </select>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="kbkchat-footer">
        <div class="btns">
          <button class="kbk-btn">Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>