import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SessionService } from 'src/app/authentication-services/session.service';
import { NOTIFICATION_COLOR, UtilService } from '../services/util.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    currentUser: any = {}
    constructor(
        // private authenticationService: AuthenticationService
        private utilService: UtilService,
        private sessionService: SessionService,
    ) {
        this.currentUser = this.sessionService.getSession();
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            console.log(err);
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                // this.authenticationService.logout();
                // location.reload();
            }
            const error = err.error.message || err.error;

            if (err.status !== 0) {
                if (err.status === 400) {
                    // auto logout if 401 response returned from api
                    // this.authenticationService.logout();
                    // location.reload();

                    if (err && err.error && err.error.error) {
                        this.utilService.showNotification(NOTIFICATION_COLOR.error, err.error.error, "bottom", "center");
                        // this.utilService.showNotification(NOTIFICATION_COLOR.error, err.error.error + '', "bottom", "center");

                        console.log(err.error.error);
                    } else if (err.error.message) {
                        this.utilService.showNotification(NOTIFICATION_COLOR.error, err.error.message, "bottom", "center");
                        console.log(err.error);
                    }

                } else if (err.error.statusCode === 404) {
                    console.log(err.error);
                } else {
                    // console.log(this.currentUser.data.user.userType.code);
                    if (this.currentUser.data.user?.agency?.code === 'RHA' &&
                        this.currentUser.data.user?.userType?.code === 'STF' &&
                        err.error.message.includes('No applications found with id')
                    ) {

                    } else {
                        this.utilService.showNotification(NOTIFICATION_COLOR.error, error, "bottom", "center");
                        return throwError(() => error);
                    }

                }

            } else if (err.status === 201) {
                this.utilService.showNotification(NOTIFICATION_COLOR.success, "Data saved successfully", "bottom", "center");
            } else {
                // this.utilService.showNotification(NOTIFICATION_COLOR.warning, "The NID was not found; please provide the names instead.", "bottom", "center");
                this.utilService.showNotification(NOTIFICATION_COLOR.warning, "Something went wrong during request.", "bottom", "center");

            }
            return throwError(() => error);
        }))
    }
}
