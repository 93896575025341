import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { jwtDecode } from 'jwt-decode';
import { AppConfig } from 'src/app/app.config';
import { ApplicationService } from 'src/app/application/services/application.service';
import { StepperService } from 'src/app/application/services/stepper.service';
import { SessionService } from 'src/app/authentication-services/session.service';
import { VALUE } from 'src/app/core/value-enum/value-enum';
import { NOTIFICATION_COLOR, UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-continue-pending-attachement',
  templateUrl: './continue-pending-attachement.component.html',
  styleUrls: ['./continue-pending-attachement.component.scss']
})
export class ContinuePendingAttachementComponent {
  pageController: any = {};
  currentUser: any = {};
  userForm!: UntypedFormGroup;
  existingApplication: any = {};
  fileData: any = {};
  outputData: any = {};
  submitted!: boolean;
  resubmitData: any = {};
  isAssociated: boolean = false;
  params: any = {};


  closeAssociatedPopup() {
    this.modalService.dismissAll();
    this.isAssociated = !this.isAssociated;

  }
  checkAssociated(event: any) {
    this.outputData.applicationId = this.pageController.applicationDetail.id;
    this.isAssociated = !this.isAssociated;
    this.openModal(event, 'md');
    // this.modalService.

    // (click)="openModal(associatedUpiContent, 'md')"
  }

  constructor(
    private stepperService: StepperService,
    private applicationService: ApplicationService,
    private utilService: UtilService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private modalService: NgbModal,
    private appConfig: AppConfig,
    private router: Router) {
    this.currentUser = this.sessionService.getSession();
    this.currentUser.userId = (jwtDecode(this.currentUser.data.token.access_token) as any).UserId;
    this.resubmitData.isResubmit = false;

    route.params.subscribe((params: any) => {

      this.params = params;
      console.log(params);
      this.applicationService.findAllWithPath(environment.applicationUrl + 'application/application/AllDetails/' + params.id)
        .subscribe(
          data => {
            if (data && data.length > 0) {


              this.pageController.applicationDetail = data[0];
              console.log(this.pageController.applicationDetail);
              this.userForm.controls['projectId'].setValue(this.pageController.applicationDetail?.projects?.id);
              if (this.pageController.applicationDetail.permitTypes) {
                this.userForm.controls['permitTypeId'].setValue(this.pageController.applicationDetail.permitTypes.id);
                this.userForm.controls['permitTypeCode'].setValue(this.pageController.applicationDetail.permitTypes.code);
              }

              this.userForm.controls['categoryTypeId'].setValue(this.pageController.applicationDetail.categoryTypes.id);
              this.userForm.controls['buildTypeId'].setValue(this.pageController.applicationDetail.buildTypes.id);
              this.userForm.controls['agencyId'].setValue(this.pageController.applicationDetail.agencyId);
              this.userForm.controls['agencyCode'].setValue(this.pageController.applicationDetail.agencyCode);
              this.userForm.controls['applicationId'].setValue(this.pageController.applicationDetail.id);

              // resubmit content
              console.log(this.pageController.applicationDetail);
              if (this.pageController.applicationDetail.applicationStatus.code === 'UNCRN') {
                this.resubmitData.isResubmit = true;
              } else {
                this.resubmitData.isResubmit = false;
              }
              // resubmit content

              this.getDocumentByPermitTypeAndCategory();
              // this.initialize(this.applicationDetail);

              // Call exiting application by project id and permit type
              // this.getExistingApplicationAndPermitType();
              // Call exiting application by project id and permit type
              // this.getDocumentByPermitTypeAndCategory();
            } else {

              // this.getProjectByParamsId(params.id);
            }
          }
        )
    })


    this.applicationService.findAllWithPath(environment.applicationUrl + 'application/permitType/code/search?search=' + VALUE.NEW_CONSTRUCTION_PERMIT_CODE)
      .subscribe(
        data => {
          this.userForm.controls['usedPermitType'].setValue(data.items[0].id);
          // this.getProject();
        },
        error => { }
      )
  }

  openModal(content: any, sizeParams: any) {
    this.modalService.open(content, { size: sizeParams, centered: true });
  }







  getDocumentByPermitTypeAndCategory() {
    // this.applicationService.findAllWithPath(environment.applicationUrl + 'application/permitType/code/search?search=' + VALUE.NEW_CONSTRUCTION_PERMIT_CODE)
    // this.applicationService.findAllWithPath(environment.applicationUrl + '')
    //   .subscribe(
    //     data => {
    //       if (data && data.items.length > 0) {
    // this.applicationService.findAllWithPath(environment.applicationUrl + 'application/requiredDocument/permitType/' + this.userForm.value.permitTypeId)


    this.applicationService.findAllWithPath(environment.applicationUrl + 'application/requiredDocument/permitType/' + this.userForm.value.permitTypeId + '/category/' + this.userForm.value.categoryTypeId)


      .subscribe(
        documents => {
          if (this.pageController && this.pageController.applicationDetail) {
            let valueData = documents.filter((i: any) => this.pageController.applicationDetail.categoryTypes.code === i.categoryTypes.code);
            this.pageController.reservedDocumentRequired = valueData;
            // this.pageController.reservedDocumentRequired = documents;
            // load document uploaded for current application
            this.loadUploadedDocuments();
            // load document uploaded for current application
          }

        }
      )
    // }

    //   }
    // )
  }



  loadUploadedDocuments() {
    console.log("Loading uploaded documents");
    this.applicationService.findAllWithPath(environment.documentUrl + 'DocMgt/documents/' + this.userForm.value.applicationId)
      .subscribe(
        documents => {
          console.log("delete is done")
          this.pageController.uploadedDocuments = documents;

          if (this.pageController.uploadedDocuments?.length === this.pageController.reservedDocumentRequired?.length
            || this.pageController.uploadedDocuments?.length > this.pageController.reservedDocumentRequired?.length
          ) {
            this.pageController.isReadyToSubmit = true;
          }

          // test
          this.pageController.documentRequired = [];
          this.pageController.documentRequired = this.pageController.reservedDocumentRequired;
          console.log(this.pageController.uploadedDocuments);
          console.log(this.pageController.documentRequired);
          this.pageController.documentRequired.forEach((document: any) => {
            let findElement = this.pageController.uploadedDocuments.find((x: any) => x.requiredDocumentId === document.id);
            if (findElement) {
              document.uploaded = findElement
            }
          });

        }
      )
  }


  get form() {
    return this.userForm.controls;
  }

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      documentRequiredId: ["", [Validators.required]],
      applicationId: [""],
      document: [""],
      userId: [this.currentUser.userId],
      applicationStatusId: [""],
      usedPermitType: [""],
      projectId: [""],
      permitTypeId: [""],
      categoryTypeId: [""],
      buildTypeId: [""],
      agencyId: [""],
      permitTypeCode: [""],
      agencyCode: [""],
    });
  }


  getProject() {

    // this.applicationService.findAllWithPath(environment.applicationUrl + 'application/project/upi/search?upi=' + this.upiInfo.upi)
    //   .subscribe(
    //     data => {
    //       this.userForm.controls['projectId'].setValue(data.items[0].id);
    //       // Call exiting application by project id and permit type
    //       this.getExistingApplicationAndPermitType();
    //       // Call exiting application by project id and permit type
    //     }
    //   )
  }






  allowToSubmit() {
    this.pageController.isChecked = !this.pageController.isChecked;
  }



  getFileDetails(e: any, docInfo: any) {
    console.log(docInfo.name);
    const fileInput: any = e.target as HTMLInputElement;

    this.pageController.fileDataName = docInfo.name
    if (docInfo.code === '1') {
      // Excel File to upload
      if (this.utilService.getCheckFileExtension(e).toLowerCase() === 'pdf') {
        this.utilService.showNotification(NOTIFICATION_COLOR.error, 'Please upload BOQ (Bill of Quantities file)', "bottom", "center");

      } else {
        this.fileData.fileName = e.target?.files[0]?.name;
        this.fileData.fileNameDisplay = e.target?.files[0]?.name;
        this.userForm.controls['documentRequiredId'].setValue(docInfo.id);
        this.handleFileSelected(e);
        this.fileData.myFiles = [];
        for (var i = 0; i < e.target.files.length; i++) {
          this.fileData.myFiles.push(e.target.files[i]);
        }
        this.fileData.file = e.target.files;
        this.onSubmit();
      }

      // Excel File to upload
    } else {
      this.fileData.fileName = e.target?.files[0]?.name;
      this.fileData.fileNameDisplay = e.target?.files[0]?.name;
      this.userForm.controls['documentRequiredId'].setValue(docInfo.id);
      this.handleFileSelected(e);
      if (this.fileData.fileType !== 'excel' && this.utilService.getCheckFileExtension(e).toLowerCase() === 'pdf') {
        this.fileData.myFiles = [];
        for (var i = 0; i < e.target.files.length; i++) {
          this.fileData.myFiles.push(e.target.files[i]);
        }
        if (this.fileData.size > this.utilService.fileSize) {
          this.utilService.showNotification(
            NOTIFICATION_COLOR.error,
            "Error: " + 'File will not be saved because it is too big, try 20MB of size',
            "bottom",
            "center"
          );
          fileInput.value = ''; // Reset input
          return;
        } else {
          this.fileData.file = e.target.files;
          this.onSubmit();
        }
      } else {
        this.utilService.showNotification(
          NOTIFICATION_COLOR.error,
          "Error: " + 'PDF File allowed only',
          "bottom",
          "center"
        );
        // Reset input after error
        setTimeout(() => {
          fileInput.value = '';
        }, 0);
      }
    }
  }


  onSubmit() {
    // if (this.userForm.invalid) {
    //   this.submitted = false;
    //   this.utilService.showNotification(NOTIFICATION_COLOR.error, 'Please fill username and password', "bottom", "center")
    //   return;
    // } else {
    let formData = new FormData();
    const fileList: FileList = this.fileData.file;
    if (fileList && fileList[0]) {
      const file: File = fileList[0];
      this.fileData.fileNameDisplay = file?.name
      formData.append('file', file, file?.name);
    }
    formData.append('requiredDocumentId', this.userForm.value.documentRequiredId);
    formData.append('applicationId', this.userForm.value.applicationId);
    this.pageController.fileSubmit = true;
    this.applicationService.saveAssetWithPathFormData(formData, environment.documentUrl + 'DocMgt/upload')
      .subscribe(
        data => {
          this.pageController.fileSubmit = false;
          this.pageController.submitted = false;
          // this.utilService.showFewMinutesNotification(NOTIFICATION_COLOR.success, "Your file uploed successfully", "bottom", "center");
          this.loadUploadedDocuments();

        }, (error) => {
          // console.log(err)
          this.pageController.submitted = false;
          this.pageController.fileSubmit = false;
          this.utilService.showFewMinutesNotification(NOTIFICATION_COLOR.error, error, "bottom", "center");
        }
      )
    // }
  }



  async handleFileSelected(event: any) {
    const size = event.srcElement.files[0].size;
    if (size < 1000 * 1000 * 1000) {
      this.fileData.size = size / 1000 / 1000;
      this.fileData.unit = "mb";
    }
  }



  openDeleteModal(content: any, sizeParams: any, value: any) {
    console.log(value);
    this.pageController.deleteData = value;
    this.modalService.open(content, { size: sizeParams, centered: true });
  }


  deleteFile() {
    this.pageController.isDeleting = true;
    this.applicationService.deleteWithPathNoId(environment.documentUrl + "DocMgt/" + this.pageController?.deleteData?.id)
      .subscribe(
        data => {
          this.modalService.dismissAll();
          console.log("delete is initiated")

          this.pageController.isDeleting = false;
          this.utilService.showNotification(NOTIFICATION_COLOR.success, "Document removed successfully", "bottom", "center");
          this.loadUploadedDocuments();
        },
        error => { this.pageController.isDeleting = false; }
      )
  }





  viewDocument(component: any, doc: any, sizeParams: any) {
    console.log(doc)
    this.pageController.loading = true;
    this.applicationService.findAllWithPath(environment.documentUrl + 'DocMgt/' + doc?.uploaded?.fileName + '/base64')
      .subscribe(
        data => {
          this.pageController.loading = false;
          if (this.utilService.isExcelFile(data.base64Data)) {
            this.utilService.downloadExcel(data.base64Data);
          } else {
            this.outputData = data;
            this.outputData.currentId = doc.id;
            this.modalService.open(component, { size: sizeParams, centered: true });
          }
        }, error => {
          this.pageController.loading = false;
        }
      )

  }








  onPrev() {
    console.log(this.userForm.value);
    // console.log(this.)
    if (this.params.permitType === 'NCP') {
      this.router.navigate(['/account/application/new-application-development-detail/', this.userForm.value.projectId]);
    } else if (this.pageController?.applicationDetail?.permitTypes && this.pageController?.applicationDetail?.permitTypes?.id) {
      this.router.navigate(['/account/application/related-permit-type-application-development-detail/' + this.pageController?.applicationDetail?.permitTypes?.id + '/' + this.userForm.value.projectId]);
    }

  }

  onNext() {

    this.submitApplication();

  }


  submitApplication() {

    this.submitted = true;

    if (this.resubmitData.isResubmit) {
      this.applicationService.findAllWithPath(environment.applicationUrl + 'application/applicationStatus/code/search?search=' + VALUE.APPLICATION_STATUS_RE_SUBMIT_CODE)
        .subscribe(
          data => {
            this.userForm.controls['applicationStatusId'].setValue(data.items[0].id);
            this.confirmSubmit();
          }, error => {
            this.submitted = false;
            this.utilService.showNotification(NOTIFICATION_COLOR.error, "Application status not found", "bottom", "center");
          });
    } else {
      this.applicationService.findAllWithPath(environment.applicationUrl + 'application/applicationStatus/code/search?search=' + VALUE.APPLICATION_STATUS_SUBMIT_CODE)
        .subscribe(
          data => {
            this.userForm.controls['applicationStatusId'].setValue(data.items[0].id);
            this.confirmSubmit();
          }, error => {
            this.submitted = false;
            this.utilService.showNotification(NOTIFICATION_COLOR.error, "Application status not found", "bottom", "center");
          });
    }

  }


  confirmSubmit() {
    // let dataToSubmit = {
    //   "projectId": "string",
    //   "userId": "string",
    //   "permitTypeId": "string",
    //   "categoryTypeId": "string",
    //   "buildTypeId": "string",
    //   "agencyId": "string",
    //   "applicationStatusId": "string",
    //   "permitTypeCode": "string",
    //   "agencyCode": "string"
    // }

    // this.applicationService.updateAssetWithoutParams(this.userForm.value, environment.applicationUrl + 'application/application/submit/' + this.userForm.value.applicationId)




    if (this.resubmitData.isResubmit) {
      this.applicationService.updateAssetWithoutParams(this.userForm.value, environment.applicationUrl + 'application/application/resubmit/' + this.userForm.value.applicationId)
        .subscribe(
          data => {
            this.utilService.showNotification(NOTIFICATION_COLOR.success, "Your application submitted successfully", "bottom", "center");
            this.submitted = false;
            this.modalService.dismissAll();
            this.router.navigate(['/account/application/applications']);
          }, error => {
            this.submitted = false;
          }
        )
    } else {
      this.applicationService.saveAssetWithPath(this.userForm.value, environment.applicationUrl + 'application/application/submit/' + this.userForm.value.applicationId)
        .subscribe(
          data => {
            this.utilService.showNotification(NOTIFICATION_COLOR.success, "Your application submitted successfully", "bottom", "center");
            this.submitted = false;
            this.modalService.dismissAll();
            this.router.navigate(['/account/application/applications']);
          }, error => {
            this.submitted = false;
          }
        )
    }




  }

  cancel() {
    this.modalService.dismissAll();
  }
}
