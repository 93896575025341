import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NOTIFICATION_COLOR, UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent {
  saveForm!: UntypedFormGroup;
  resendTokenForm!: UntypedFormGroup;
  submitted: boolean = false;
  isFailedToSubmit: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService,
    public utilService: UtilService,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe((params: any) => {
      if (params) {
        this.saveForm = this.formBuilder.group({
          code: [params.code, [Validators.required]],
        });
        // this.authService.findAllWithPath(environment.authUrl + 'auth/verify/' + this.saveForm.value.code)
        //   .subscribe(
        //     data => {
        //       this.utilService.showNotification(NOTIFICATION_COLOR.success, data.message, 'bottom', 'center');
        //       this.router.navigate(['/login'])
        //     }, error => {
        //       this.isFailedToSubmit = true;
        //     }
        //   )
      } else {
        this.saveForm = this.formBuilder.group({
          code: ["", [Validators.required]],
        });
        this.utilService.showNotification(NOTIFICATION_COLOR.error, "Your token not found", "bottom", "center");
      }

    });

  }

  ngOnInit(): void {

    this.resendTokenForm = this.formBuilder.group({
      email: ["", [Validators.required]],
      isResending: [false]
    });

    // this.authService.findAllWithPath(environment.authUrl + 'auth/verify/' + this.saveForm.value.code)
    // .subscribe(
    //   data => {
    //     this.utilService.showNotification(NOTIFICATION_COLOR.success, data.message, 'bottom', 'center');
    //     this.router.navigate(['/login'])
    //   }, error => {
    //     this.isFailedToSubmit = true;
    //   }
    // )
  }

  resendMethod() {
    this.resendTokenForm.controls['isResending'].setValue(!this.resendTokenForm.value.isResending);
  }


  onResendTokenSubmit() {
    this.submitted = true;
    this.authService.saveAssetWithPath(this.resendTokenForm.value, environment.authUrl + 'auth/resend-verification')
      .subscribe(
        data => {
          this.submitted = false;
          this.utilService.showNotification(NOTIFICATION_COLOR.success, data.message, 'bottom', 'center');
          this.resendMethod();
        }, error => {
          this.submitted = false;
        }
      )
  }

  onSubmit() {
    if (this.saveForm.invalid) {
      this.submitted = false;
      this.utilService.showNotification(NOTIFICATION_COLOR.error, 'Please fill all required fields', "bottom", "center")
      return;
    } else {
      this.submitted = true;
      this.authService.findAllWithPath(environment.authUrl + 'auth/verify/' + this.saveForm.value.code)
        .subscribe(
          data => {
            this.submitted = false;
            this.utilService.showNotification(NOTIFICATION_COLOR.success, data.message, 'bottom', 'center');
            this.router.navigate(['/login'])
          }, error => {
            this.submitted = false;
          }
        )
      // this.isFailedToSubmit = false;
      // this.authService.findAllWithPath(environment.authUrl + 'auth/verify/' + this.saveForm.value.code)
      //   .subscribe(
      //     data => {
      //       this.isFailedToSubmit = true;
      //       this.utilService.showNotification(NOTIFICATION_COLOR.success, data.message, 'bottom', 'center');
      //       this.router.navigate(['/login'])
      //     }, error => {
      //       this.isFailedToSubmit = true;
      //     }
      //   )
    }
  }
}
