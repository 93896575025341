import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { jwtDecode } from 'jwt-decode';
import { AppConfig } from 'src/app/app.config';
import { ApplicationService } from 'src/app/application/services/application.service';
import { SessionService } from 'src/app/authentication-services/session.service';
import { VALUE } from 'src/app/core/value-enum/value-enum';
import { APIURLPATH } from 'src/app/shared/services/url-path';
import { NOTIFICATION_COLOR, UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-continue-pending-application',
  templateUrl: './continue-pending-application.component.html',
  styleUrls: ['./continue-pending-application.component.scss']
})
export class ContinuePendingApplicationComponent {
  submitted!: boolean;
  applicationDetail: any = {};
  @Output() backToParent = new EventEmitter();
  userForm!: UntypedFormGroup;
  outputData: any = {};
  technologySurveys: any[] = [];
  currentUser: any = {};
  upiInfo: any = {};
  isReadOnly: boolean = true;
  isAssociated: boolean = false;
  permitTypes: any[] = [];

  buildingTypes: any[] = [];
  categoryTypes: any[] = [];

  constructor(

    private applicationService: ApplicationService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private sessionService: SessionService,
    private utilService: UtilService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private appConfig: AppConfig) {
    this.currentUser = this.sessionService.getSession();
    this.currentUser.userId = (jwtDecode(this.currentUser.data.token.access_token) as any).UserId;

    this.upiInfo = JSON.parse(localStorage.getItem(this.appConfig.UPI_NEW_INFO) as any);
    console.log(this.upiInfo);

    this.loadLookups();



  }


  checkAssociated(event: any) {
    console.log(this.applicationDetail);
    this.outputData.applicationId = this.applicationDetail.projects.id;
    this.outputData.combiningPlotSize = this.applicationDetail.combiningPlotSize;
    this.outputData.plotSize = this.applicationDetail.plotSize;
    this.isAssociated = !this.isAssociated;
    this.openModal(event, 'lg');
  }
  closeAssociatedPopup(event: any) {
    this.modalService.dismissAll();
    this.isAssociated = !this.isAssociated;

  }
  openModal(content: any, sizeParams: any) {
    this.modalService.open(content, { size: sizeParams, centered: true });
  }


  cancel() {
    this.router.navigate(['/account/application/applications'])
  }


  focusOutFunction() {
    console.log(this.userForm.value.buildUpArea);
    console.log(this.userForm.value.plotSize);
    this.userForm.controls['percentageSpaceUse'].setValue(((this.userForm.value.buildUpArea / this.userForm.value.plotSize) * 100).toFixed(2));
    console.log(this.userForm.value.percentageSpaceUse);
  }

  calculateGrossFlowArea() {
    if (this.userForm.value.buildUpArea && this.userForm.value.numberOfFloor) {
      this.userForm.controls['grossFloorArea'].setValue((this.userForm.value.buildUpArea) * ((+this.userForm.value.numberOfFloor) + 1));
    }
  }

  ngOnInit(): void {
    this.initialize('')
    this.route.params.subscribe((param: any) => {
      this.applicationService.findAllWithPath(environment.applicationUrl + 'application/application/AllDetails/' + param.id)
        .subscribe(
          data => {
            if (data && data.length > 0) {
              this.applicationDetail = data[0];
              console.log(this.applicationDetail);
              this.initialize(this.applicationDetail);
            } else {
              this.initialize('');
            }

            // console.log(this.applicationDetail);
            // console.log(this.applicationDetail.projectDescription)
          }
        )
    })

  }


  initialize(event: any) {
    if (event === '') {
      this.userForm = this.formBuilder.group({
        upi: [""],
        isUnderMortgage: [false],
        isUnderRestriction: [false],
        centralCoordinateX: [""],
        centralCoordinateY: [""],
        villageName: ["", [Validators.required]],
        cellCode: ["", [Validators.required]],
        sectorCode: ["", [Validators.required]],
        districtCode: ["", [Validators.required]],
        provinceCode: ['', [Validators.required]],
        cellName: ["", [Validators.required]],
        sectorName: ["", [Validators.required]],
        districtName: ["", [Validators.required]],
        provinceName: ["", [Validators.required]],
        selectedUse: ["", [Validators.required]],
        selectedCategoryUse: ["", [Validators.required]],
        projectName: ["", [Validators.required]],
        projectDescription: [""], // from first page
        plotSize: ["", [Validators.required]],
        originalPlotSize: [""],
        combiningPlotSize: ["", [Validators.required]],
        userId: [this.currentUser.userId],
        agencyId: [""],   //this is for cok
        technologySurveyId: [""],
        projectStatusId: [""],
        isFromOldSystem: [''],


        buildUpArea: ["", [Validators.required]],
        numberOfFloor: ["", [Validators.required]],    // help to calculate amount
        grossFloorArea: ["", [Validators.required]],
        numberOfParkingSpace: [""],
        priceOfDwellingUnitRwf: ["", [Validators.required]],
        capacityInformation: ["", [Validators.required]],
        numberOfDwellingUnits: [""],
        DescriptionOfOperation: [""],
        percentageSpaceUse: ["", [Validators.required]],
        waterConsumption: ["", [Validators.required]],
        electricityConsumption: ["", [Validators.required]],
        DistanceToTheNearestLandIn: ["", [Validators.required]],
        ProjectCostInUSD: ["", [Validators.required]],
        ProjectCostInRwf: ["", [Validators.required]],
        permitTypeId: [""],
        buildTypeId: [""]

      }, { validator: this.buildUpAreaValidator });
    } else {
      this.userForm = this.formBuilder.group({
        id: [this.applicationDetail.id],
        upi: [this.applicationDetail.projects?.upi],
        isUnderMortgage: [this.applicationDetail.projects?.isUnderMortgage],
        isUnderRestriction: [this.applicationDetail.projects?.isUnderRestriction],
        centralCoordinateX: [this.applicationDetail.projects?.centralCoordinateX],
        centralCoordinateY: [this.applicationDetail.projects?.centralCoordinateY],
        villageName: [this.applicationDetail.projects?.villageName, [Validators.required]],
        cellCode: [this.applicationDetail.projects?.cellCode, [Validators.required]],
        sectorCode: [this.applicationDetail.projects?.sectorCode, [Validators.required]],
        districtCode: [this.applicationDetail.projects?.districtCode, [Validators.required]],
        provinceCode: [this.applicationDetail.projects?.provinceCode, [Validators.required]],
        cellName: [this.applicationDetail.projects?.cellName, [Validators.required]],
        sectorName: [this.applicationDetail.projects?.sectorName, [Validators.required]],
        districtName: [this.applicationDetail.projects?.districtName, [Validators.required]],
        provinceName: [this.applicationDetail.projects?.provinceName, [Validators.required]],
        selectedUse: [this.applicationDetail.projects?.selectedUse, [Validators.required]],
        selectedCategoryUse: [this.applicationDetail.projects?.selectedCategoryUse, [Validators.required]],
        projectName: [this.applicationDetail.projects?.upi, [Validators.required]],
        projectDescription: [this.applicationDetail.projects?.projectDescription], // from first page
        plotSize: [this.applicationDetail.projects?.plotSize, [Validators.required]],
        originalPlotSize: [this.applicationDetail.projects?.plotSize,],
        combiningPlotSize: [this.applicationDetail.combiningPlotSize],
        userId: [this.currentUser.userId],
        agencyId: [this.applicationDetail.projects?.agencyId],   //this is for cok
        technologySurveyId: [""],
        projectStatusId: [this.applicationDetail.applicationStatus.id],
        isFromOldSystem: [this.applicationDetail.projects?.isFromOldSystem],

        buildUpArea: [this.applicationDetail.buildUpArea, [Validators.required]],
        numberOfFloor: [this.applicationDetail.numberOfFloor, [Validators.required]],    // help to calculate amount
        grossFloorArea: [this.applicationDetail.grossFloorArea, [Validators.required]],
        numberOfParkingSpace: [this.applicationDetail.numberOfParkingSpace],
        priceOfDwellingUnitRwf: [this.applicationDetail.priceOfDwellingUnitRwf, [Validators.required]],
        capacityInformation: [this.applicationDetail.capacityInformation, [Validators.required]],
        numberOfDwellingUnits: [this.applicationDetail.numberOfDwellingUnits],
        DescriptionOfOperation: [this.applicationDetail.DescriptionOfOperation],
        percentageSpaceUse: [this.applicationDetail.percentageSpaceUse, [Validators.required]],
        waterConsumption: [this.applicationDetail.waterConsumption, [Validators.required]],
        electricityConsumption: [this.applicationDetail.electricityConsumption, [Validators.required]],
        DistanceToTheNearestLandIn: [this.applicationDetail.DistanceToTheNearestLandIn, [Validators.required]],
        ProjectCostInUSD: [this.applicationDetail.ProjectCostInUSD, [Validators.required]],
        ProjectCostInRwf: [this.applicationDetail.ProjectCostInRwf, [Validators.required]],
        permitTypeId: [this.applicationDetail.permitTypes.id],
        buildTypeId: [this.applicationDetail.buildTypes.id],
      }, { validator: this.buildUpAreaValidator });
      console.log(this.userForm.value);
    }

    this.getProjectStatus();
  }


  buildUpAreaValidator: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
    const plotSize = control.get('plotSize');
    const buildUpArea = control.get('buildUpArea');
    const percentageSpaceUse: any = control.get('percentageSpaceUse');
    if (plotSize && buildUpArea && plotSize.value && buildUpArea.value) {
      if (buildUpArea.value > plotSize.value) {
        console.log('Hello there is greater that plot size');
        return { 'greaterThanPlotSize': true };
      }
    }
    return null;
  };


  next() {
    this.router.navigate(['/account/application/resume-application-attachment/NCP', this.applicationDetail.id]);
  }

  getAgencyByCode(agencyCode: any) {
    this.applicationService.findAllWithPath(environment.authUrl + 'user-management/agency/code/search?search=' + agencyCode)
      .subscribe(
        data => {
          this.userForm.controls['agencyId'].setValue(data.items[0].id);
        },
        error => { }
      )
  }

  getAgencyByDistrictCode(districtCode: any) {
    this.applicationService.findAllWithPath(environment.authUrl + 'user-management/agencyDistricts/ByDistrictCode/' + districtCode)
      .subscribe(
        data => {
          this.userForm.controls['agencyId'].setValue(data.id);
        },
        error => { }
      )
  }











  getProjectStatus() {
    this.applicationService.findAllWithPath(environment.applicationUrl + 'application/projectStatus/code/search?search=' + VALUE.PROJECT_STATUS_CREATED_CODE)
      .subscribe(
        data => { this.userForm.controls['projectStatusId'].setValue(data.items[0].id); },
        error => { }
      )
  }


  onSubmit() {
    if (this.userForm.invalid) {
      this.submitted = false;
      this.utilService.showNotification(NOTIFICATION_COLOR.error, 'Please fill username and password', "bottom", "center")
      return;
    } else {

      this.userForm.controls['isFromOldSystem'].setValue(this.upiInfo?.isFromOldSystem);
      this.userForm.value.plotSize = this.userForm.controls['plotSize'].setValue(this.userForm.value.plotSize);

      this.submitted = true;
      this.applicationService.saveWithPath(this.userForm.value, environment.applicationUrl + 'application/project')
        .subscribe(
          data => {
            this.utilService.showNotification(NOTIFICATION_COLOR.success, "Your project saved successfully", "bottom", "center");
            if (this.upiInfo?.isFromOldSystem) {
              // this.router.navigate(['/account/application/other-application/', this.userForm.value.upi]);
              this.router.navigate(['/account/application/other-application/', data.id]);
            } else {
              this.backToParent.emit(data);
            }

            this.submitted = false;
          }, error => { this.submitted = false; }
        )
    }
  }

  loadLookups() {
    this.applicationService.findAllWithPath(environment.applicationUrl + APIURLPATH.TECHNOLOGYSURVEY)
      .subscribe(
        data => { this.technologySurveys = data; }
      )


    this.applicationService.findAllWithPath(environment.applicationUrl + APIURLPATH.PERMITYPES)
      .subscribe(
        data => {
          this.permitTypes = data;
        },
      )


    this.applicationService.findAllWithPath(environment.applicationUrl + APIURLPATH.BUILDING_TYPE)
      .subscribe(
        data => { this.buildingTypes = data; },
      )

    this.applicationService.findAllWithPath(environment.applicationUrl + APIURLPATH.CATEGORYTYPE)
      .subscribe(
        data => {
          this.categoryTypes = data;
        }
      )
  }
}
