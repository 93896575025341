<div class="login-container">
    <div class="login-content">
        <div class="illust">
            <img class="logo" [routerLink]="['/']" src="assets/ikons/logo/NavLogo.svg" alt="">
            <img class="ill" src="assets/imgs/SVG/illustration.svg" alt="">
        </div>
        <div class="dtials">
            <form class="form" *ngIf="!resendTokenForm.value.isResending" [formGroup]="saveForm" (ngSubmit)="onSubmit()">
                <div class="form-header">
                    <h2 class="display-md">Verify Code</h2>
                </div>
                <div class="form-body">
                    <div class="form-input">
                        <label>Code</label>
                        <div>
                            <!-- <input class="flg" type="text" id="code" name="code" formControlName="code" [readonly]="!isFailedToSubmit"
                            required /> -->
                            <input class="flg" type="number" id="code" name="code" formControlName="code" required />
                        </div>
                    </div>
                </div>
                <div class="form-footer">
                    <!-- <button *ngIf="isFailedToSubmit" class="kbk-btn kbk-btn-lg kbk-btn-main"
                        [disabled]="saveForm.invalid" type="submit">Submit</button>
                    <button *ngIf="!isFailedToSubmit" class="kbk-btn kbk-btn-lg kbk-btn-main"
                        type="button">Verifying..</button> -->
                    <button *ngIf="!submitted" class="kbk-btn kbk-btn-lg kbk-btn-main" [disabled]="saveForm.invalid"
                        type="submit">Submit</button>
                    <button *ngIf="submitted" class="kbk-btn kbk-btn-lg kbk-btn-main" type="button">Verifying..</button>
                    <a class="kbk-link" (click)="resendMethod()">Resend token</a>
                </div>
            </form>
            <form *ngIf="resendTokenForm.value.isResending" class="form" [formGroup]="resendTokenForm" (ngSubmit)="onResendTokenSubmit()">
                <div class="form-header">
                    <h2 class="display-md">Verify Code</h2>
                </div>
                <div class="form-body">
                    <div class="form-input">
                        <label>Email</label>
                        <div>
                          
                            <input class="flg" type="text" id="email" name="email" formControlName="email"
                            placeholder="Your email please" required />
                        </div>
                    </div>
                </div>
                <div class="form-footer">
                   
                    <button *ngIf="!submitted" class="kbk-btn kbk-btn-lg kbk-btn-main" [disabled]="resendTokenForm.invalid"
                        type="submit">Resend</button>
                    <button *ngIf="submitted" class="kbk-btn kbk-btn-lg kbk-btn-main" type="button">Resending..</button>
                    <a class="kbk-link" (click)="resendMethod()">Back</a>
                </div>
            </form>
        </div>
    </div>
</div>