<div class="modol-content">
    <form (ngSubmit)="saveUser()" [formGroup]="fieldForm" autocomplete="off">
        <input type="hidden" name="id" value="" formControlName="id" />
        <div class="form-set">
            <div class="form-input">
                <label>Name</label>
                <div>
                    <input type="text" id="name" name="name" formControlName="name" required
                    [ngClass]="{ 'is-invalid': submitted && form['name'].errors }">
                    <div *ngIf="submitted && form['name'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['name'].errors['required']">Name is required</div>
                    </div>
                </div>
            </div>
            <div class="form-input">
                <label>Code</label>
                <div>
                    <input type="text" id="code" name="code" formControlName="code" required
                    [ngClass]="{ 'is-invalid': submitted && form['code'].errors }"
                    [readonly]="this.inputData.userForm.value.id !== ''">
                    <div *ngIf="submitted && form['code'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['code'].errors['required']">Code is required</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="kbk-x-c sp-sm mt-md">
            <button type="button" class="kbk-btn kbk-btn-sec">Cancel</button>
            <button *ngIf="submitted" type="button" class="kbk-btn kbk-btn-sec">Wait...</button>
            <button *ngIf="!submitted" type="submit"
            [disabled]="fieldForm.invalid" class="kbk-btn kbk-btn-main">{{this.inputData.type === 'edit' ? 'Update' : 'Save'}}</button>
        </div>
    </form>
</div>